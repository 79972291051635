.nav-item-link:hover + .nav-item-drop,
.nav-items:hover .nav-item-drop {
  opacity: 1;
  -webkit-transform: translateY(0px) translateX(-51%);
  -ms-transform: translateY(0px) translateX(-51%);
  transform: translateY(0px) translateX(-51%);
  height: auto;
  z-index: 1;
  visibility: visible;
}

.nav-item-drop:focus-within a {
  outline: none;
}

.nav-items .nav-item-link:hover > .nav-items,
.nav-items .nav-item-link .nav-items:hover,
.nav-items .nav-item-link .nav-items:focus {
  visibility: visible;
  opacity: 1;
  display: block;
}

.nav-items .nav-item-active {
  color: #b2bdb6;
}

.topbar-open {
  overflow: hidden !important;
}
